<template>
  <div
    class="w-full h-full bg-no-repeat bg-cover flex items-center justify-center bg-primary-100"
    :style="{ backgroundImage: `url(${bgImage})` }"
  >
    <div class="flex w-2/3 h-2/3 rounded-lg bg-white">
      <!-- Logo Container -->
      <div class="flex flex-1 items-center px-3 py-1 bg-primary rounded-lg">
        <img :src="logoImage" class="w-full px-8" />
      </div>

      <!-- Form Container -->
      <div
        class="flex flex-col flex-1 justify-center items-center text-center bg-white rounded-lg"
      >
        <div class="w-5/6">
          <p class="text-2xl text-primary w-full my-3 font-bold">
            Welcome Back
          </p>
          <p class="text-xs text-gray-400 w-full">
            We're happy you're here! Please sign in below.
          </p>

          <!-- Email Form -->
          <form
            ref="formRef"
            class="my-10 text-left text-xs text-gray-400"
            :onsubmit="signIn"
          >
            <div>
              <label for="email" class="font-bold p-0.5">Email</label>
              <input
                id="current-email"
                ref="emailInput"
                v-model="email"
                type="email"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                name="username"
                autocomplete="email"
                class="border-2 hover:border-2 w-full rounded p-2 text-xs"
                required
                @keyup.enter="signIn"
              />
            </div>

            <div class="pt-2">
              <label for="password" class="font-bold p-0.5">Password</label>
              <input
                id="current-password"
                ref="passwordInput"
                v-model="password"
                type="password"
                name="password"
                autocomplete="current-password"
                class="border-2 hover:border-2 w-full rounded p-2 text-xs"
                minlength="1"
                required
                @keyup.enter="signIn"
              />
            </div>

            <SolidButton
              type="button"
              class="text-white py-2 my-3 w-full px-5 rounded"
              :class="
                isValid && !loginProgress ? '!bg-primary' : '!bg-gray-500'
              "
              :disabled="!isValid || loginProgress"
              @click="e => signIn(e)"
            >
              Let's Go!
            </SolidButton>

            <p class="text-red-400">
              {{ errorMessage }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { ref, onMounted } from 'vue';
import { Auth } from 'aws-amplify';
import bgImage from '@/assets/login-background.png';
import logoImage from '@/assets/auxo-White-3C.png';

import { useEventListener } from '@vueuse/core';
import SolidButton from '@/components/buttons/SolidButton.vue';

const email = ref('');
const password = ref('');
const errorMessage = ref('');
const showPassword = ref(false);
const isFormValid = ref(false);
const loginProgress = ref(false);

const emailInput = ref(null);
const formRef = ref(null);

const isValid = () => {
  return isFormValid.value;
};

useEventListener(formRef, 'input', () => {
  isFormValid.value = formRef.value.checkValidity();
});

onMounted(() => {
  emailInput.value.focus();
});

async function signIn() {
  if (!isValid() === true) {
    formRef.value.reportValidity();
    return;
  }

  loginProgress.value = true;
  await Auth.signIn(email.value.toLowerCase(), password.value)
    .then(async response => {
      if (response !== undefined) {
        // Success
        router.push('/lead');
      }
    })
    .catch(() => {
      email.value = '';
      password.value = '';
      showPassword.value = false;
      loginProgress.value = false;
      errorMessage.value =
        'Oops! There was an issue authenticating your login.';
    });
}
</script>
