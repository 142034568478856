<template>
  <Suspense>
    <div class="h-screen w-full">
      <router-view />
    </div>
  </Suspense>
</template>

<script setup>
import { Auth, Hub } from 'aws-amplify';
import { useIdle, useEventListener } from '@vueuse/core';
import { onMounted, watch } from 'vue';
import { useUsersStore } from '@/stores/useUsers';
import { useMetrics } from '@/stores/useMetrics';
import { useWebSockets } from '@/stores/useWebSockets';
import { getUserInitials, getUsername } from './utils/helpers';

const users_store = useUsersStore();
const metrics_store = useMetrics();
const websocket_Store = useWebSockets();

Hub.listen('auth', async ({ payload: { event, data, message } }) => {
  switch (event) {
    case 'signIn':
    case 'cognitoHostedUI':
      console.debug(`${event}: Authenticated...`);

      // Add cognito id token to active user pinia store
      await Auth.currentSession().then(async res => {
        const cognitoIdToken = res.getIdToken();
        const payload = cognitoIdToken.decodePayload();
        websocket_Store.connect();
        await Promise.resolve(users_store.getUserByEmail(payload.email))
          .then(value => {
            const activeUserID = value.auxo_user_id;
            const activeUsername = value.username;

            metrics_store.activeUserID = activeUserID;
            metrics_store.activeUsername = activeUsername;
            metrics_store.activeUserDailyComplete = 0;

            users_store.activeUser = {
              ...payload,
              username: activeUsername,
              initials: getUserInitials(payload),
              auxo_user_id: activeUserID,
              photo_url: value.photo_url ? value.photo_url : 'none',
              is_active: value.is_active,
            };
          })
          .finally(() => {
            users_store.getAllUsers();
            users_store.authActivity('login');
            metrics_store.queryForUserMetrics();
            Auth.currentAuthenticatedUser().then(res => {
              console.debug(res);
            });
          });
      });

      break;
    case 'signOut':
      console.debug(event, data);
      break;
    case 'signIn_failure':
      break;
    case 'customState_failure':
      console.error(`${event}: ${message}: `, data);
      break;
  }
});

const TIMEOUT = process.env.VUE_APP_AUTH_TIMEOUT || 30;

const { idle } = useIdle(TIMEOUT * 60 * 1000);

// Reload the current session's active user on page refresh
onMounted(async () => {
  try {
    await Auth.currentSession().then(async res => {
      const cognitoIdToken = res.getIdToken();
      const payload = cognitoIdToken.decodePayload();
      websocket_Store.connect();
      const activeUsername = getUsername(payload?.email);
      users_store.activeUser = {
        ...payload,
        username: activeUsername,
        initials: getUserInitials(payload),
      };
      const user_detail = await users_store.getUserByEmail(payload?.email);
      users_store.activeUser.auxo_user_id = user_detail.auxo_user_id;
      user_detail.photo_url
        ? (users_store.activeUser.photo_url = user_detail.photo_url)
        : (users_store.activeUser.photo_url = 'none');
      users_store.activeUser.initials = [
        user_detail.first_name[0].toUpperCase(),
        user_detail.last_name[0].toUpperCase(),
      ].join('');
      users_store.activeUser.is_active = user_detail.is_active;
      metrics_store.activeUserID = user_detail.auxo_user_id;
      await metrics_store.queryForUserMetrics();
      metrics_store.activeUsername = activeUsername;
    });
  } catch (error) {
    // This error is expected, because on the login page there is no active user
    // and you are immediately redirected
  } finally {
    if (users_store.usersLoading == false && users_store.activeUser != null) {
      await users_store.getAllUsers();
    }
  }
});

onMounted(async () => {
  if (users_store.isLoading == false) {
    users_store.isLoading = true;
    await users_store.getAllUsers().then(() => {
      users_store.isLoading = false;
    });
  }
});

watch(
  () => idle.value,
  () => {
    if (idle.value == true) {
      users_store.logOut();
    }
  }
);

// TODO Track user logout on browser close
// useEventListener(window, 'beforeunload', async e => {
//   await users_store.authActivity('logout');
// });
</script>
