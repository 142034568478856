import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import './index.css';
import { Tabs, Tab } from 'vue3-tabs-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { capitalize } from 'vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const pinia = createPinia();
const app = createApp(App)
  .component('tabs', Tabs)
  .component('tab', Tab)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('date-picker', VueTailwindDatepicker)
  .component('v-select', vSelect);

app.config.globalProperties.window = window;

Amplify.configure(awsExports);

app.use(router).use(pinia).mount('#app');

document.title = `Auxo${
  process.env.VUE_APP_CLIENT_PREFIX
    ? ` - ${capitalize(process.env.VUE_APP_CLIENT_PREFIX)}`
    : ''
}`;
