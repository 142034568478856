<template>
  <ModalComponent
    :modal-active="props.open"
    :title="props.title"
    :close="props.close"
  >
    <div class="flex flex-col">
      <div
        v-show="
          props.priority
            ? worklists_store.isPriorityLoading
            : worklists_store.isLoading
        "
        class="flex items-center justify-center"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="
          !(props.priority
            ? worklists_store.isPriorityLoading
            : worklists_store.isLoading)
        "
      >
        <form
          id="addWorklistForm"
          class="mt-5 text-left text-xs text-gray-400"
          :onsubmit="save"
        >
          <BaseInput
            v-model="state.worklistName"
            :autofocus="true"
            :label="priority ? 'Project Name*' : 'Worklist Name*'"
            :class="error && !state.worklistName ? 'border-red-700' : ''"
          />

          <BaseInput
            v-if="priority === true"
            v-model="state.expiration_date"
            label="Expiration Date"
            type="date"
            :min-date="new Date()"
          />

          <div class="flex flex-col justify-end m-0.5 p-1 cursor-default">
            <label class="font-bold p-0.5">Contributors</label>

            <!-- display assigned contributors -->

            <div
              v-show="!isEditingContributors"
              class="flex justify-center"
              @click="isEditingContributors = true"
            >
              <div class="flex w-full justify-left overflow-hidden pl-2">
                <div
                  class="cursor-pointer font-extrabold bg-secondary rounded-full -ml-1.5 p-1 px-2 text-white text-[18px] w-10 h-10 text-center text-monospace flex justify-center items-center border border-secondary"
                >
                  <font-awesome-icon :icon="faPlus" />
                </div>
                <template
                  v-for="user in state.contributors.slice(0, 3)"
                  :key="user.username"
                >
                  <div class="-ml-1.5">
                    <UserPicture
                      :user="users_store.getUserByUsername(user.username)"
                      :style="'bg-white'"
                    />
                  </div>
                </template>
                <div
                  v-show="state.contributors.length > 3"
                  class="rounded-full -ml-1.5 p-1 px-2 bg-white text-primary text-[18px] w-10 h-10 text-center text-monospace flex justify-center items-center border border-primary"
                >
                  +{{ state.contributors.length - 3 }}
                </div>
              </div>
            </div>

            <!-- edit assigned contributors -->
            <div
              v-show="isEditingContributors"
              class="flex border rounded border-gray"
              @click="isEditingContributors = true"
            >
              <div class="h-36 overflow-y-scroll w-full p-2">
                <template
                  v-for="(user, index) in users_store.users"
                  :key="index"
                >
                  <div class="flex mb-1">
                    <input
                      prevent
                      type="checkbox"
                      :checked="isUserSelected(user)"
                      class="mr-1"
                      @click="contributorSelected(user)"
                    />
                    <p>{{ user.first_name + ' ' + user.last_name }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            v-show="error"
            class="text-red-500 text-center border-solid border rounded p-0.5 mt-3 border-red-500 bg-red-100"
          >
            {{ error }}
          </div>
          <div class="flex justify-end mt-5">
            <OutlinedButton class="mr-[1em] w-16" @click="() => close()">
              Cancel
            </OutlinedButton>
            <SolidButton type="submit" class="w-16">Save</SolidButton>
          </div>
        </form>
      </div>
    </div>
  </ModalComponent>
</template>

<script setup>
import BaseInput from '@/components/forms/BaseInput.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { reactive, ref, watch } from 'vue';
import { useUsersStore } from '@/stores/useUsers';
import { useWorklistsStore } from '@/stores/useWorklists';
import { format } from 'date-fns';
import { useRoute } from 'vue-router';
import UserPicture from '@/components/UserPicture.vue';
import { getUsername } from '@/utils/helpers';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// when worklist exists, we are editing a worklist
const props = defineProps({
  close: {
    type: Function,
    default: () => {},
  },
  worklist: {
    type: Object,
    default() {
      return {};
    },
  },
  open: {
    type: Boolean,
    default: false,
  },
  priority: {
    type: Boolean,
    default: false,
  },
  page: {
    type: String,
    default: 'default',
  },
  title: {
    type: String,
    default: '',
  },
});

const INITIAL_STATE = {
  worklistName: '',
  contributors: [],
  is_priority: props.priority,
  expiration_date: null,
};

/* State */
const state = reactive({
  ...INITIAL_STATE,
});

const isEditingContributors = ref(false);
const error = ref(null);
const route = useRoute();
const users_store = useUsersStore();
const worklists_store = useWorklistsStore();

watch(
  () => props.open,
  value => {
    // If open
    if (value === true) {
      //If editing load worklist, else reset
      if (Object.keys(props.worklist || {}).length > 0) {
        loadState({ ...props.worklist });
        isEditingContributors.value = false;
        error.value = null;
      } else resetState();
    }
  }
);

watch(
  () => props.priority,
  value => {
    state.is_priority = value;
  }
);

function loadState(wl) {
  state.worklistName = wl.work_list_name;
  state.contributors = wl?.assignees ? [...wl.assignees] : [];
  state.expiration_date = wl.expiration_date
    ? format(new Date(wl.expiration_date.toString().slice(0, 24)), 'yyyy-MM-dd')
    : null;
}

function resetState() {
  loadState({ ...INITIAL_STATE });
  isEditingContributors.value = false;
  error.value = null;
}

watch(
  () => props.worklist,
  value => {
    if (value === null) resetState();
  }
);

function contributorSelected(user) {
  const index = state.contributors.findIndex(u => u.username === user.username);

  // user already selected
  if (index !== -1) {
    state.contributors.splice(index, 1);
  } else {
    state.contributors.push(user);
  }
}

function isUserSelected(user) {
  const exists =
    state.contributors?.findIndex(u => u.username === user.username) !== -1;

  return exists;
}

async function updateAssignedUsers(worklistId) {
  const original = props.worklist?.assignees || [];
  const updated = state.contributors;

  for (let i = 0; i < updated.length; i++) {
    const contributor = updated[i];
    const contributorAdded =
      original.findIndex(c => c.username === contributor.username) === -1;
    if (contributorAdded)
      await worklists_store.addAssigneeToWorklist(
        worklistId,
        contributor.username
      );
  }

  for (let i = 0; i < original.length; i++) {
    const contributor = original[i];
    const contributorDeleted =
      updated.findIndex(c => c.username === contributor.username) === -1;

    if (contributorDeleted)
      await worklists_store.deleteAssigneeFromWorklist(
        worklistId,
        contributor.username
      );
  }
}

function close() {
  resetState();
  props.close();
}

async function save(e) {
  e.preventDefault();

  props.priority
    ? (worklists_store.isPriorityLoading = true)
    : (worklists_store.isLoading = true);

  const wlPayload = {
    work_list_name: state.worklistName,
    is_priority: state.is_priority,
  };

  if (props.priority)
    wlPayload.expiration_date =
      state.expiration_date != '' ? state.expiration_date : null;

  // Editing WorkList/Priority Project
  if (props.worklist) {
    const worklistId = props.worklist.work_list_id;
    await updateAssignedUsers(worklistId);

    const response = await worklists_store.updateWorklist(
      worklistId,
      wlPayload
    );

    if (response.success === true) {
      if (props.page === 'users')
        await worklists_store.fetchUserWorklists(
          getUsername(route.params.email)
        );
      else await worklists_store.fetchWorklists(props.priority);
      close();
    } else {
      error.value = response.message;
    }
    props.priority
      ? (worklists_store.isPriorityLoading = false)
      : (worklists_store.isLoading = false);
  }

  // WorkList/Priority Project
  else {
    if (!state.worklistName) {
      error.value = 'Please complete the required field(s).';
      props.priority
        ? (worklists_store.isPriorityLoading = false)
        : (worklists_store.isLoading = false);
      return;
    }

    const response = await worklists_store.postWorklist(wlPayload);

    if (response.success === true) {
      await updateAssignedUsers(response.data);
      await worklists_store.fetchWorklists(props.priority);
      close();
    } else {
      error.value = response.message;
    }

    props.priority
      ? (worklists_store.isPriorityLoading = false)
      : (worklists_store.isLoading = false);
  }
}
</script>
