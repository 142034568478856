import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import getDynamicPath from '../utils/getDynamicPath';
import { getAuthenticatedHeaders } from '@/utils/auth';

export const useChatbotStore = defineStore('chatbot', {
  state: () => ({
    notFound: false,
    responseLoading: false,
    sessionID: undefined,
  }),
  getters: {
    //no getters yet
  },
  actions: {
    async generateAndFormatResponse(prompt, props, user, messageList) {
      const headers = await getAuthenticatedHeaders();

      const payload = {
        body: {
          message: prompt,
          phase: props.rspGroup.phase,
          status: props.rspGroup.status,
          reason: props.rspGroup.reason,
          userID: user.auxo_user_id,
          sessionID: this.sessionID,
          rsn: props.rspGroup.rsn,
          existingConversation: messageList,
        },
        ...headers,
      };

      const response = await API.post(
        'insurance',
        getDynamicPath('chat'),
        payload
      );

      this.sessionID = response.data.sessionID;
      const output = response.data?.response_text ?? null;

      const presignedURL = response.data.presignedURL;

      const body = output;
      const linkText = '';
      const imageUrl = '';

      const text = {
        body,
        imageUrl,
        linkText,
        linkUrl: presignedURL,
        sessionID: this.sessionID,
      };

      return text;
    },
  },
});
