import StatsIcon from '@/assets/sidebar-icons/StatsIcon.vue';
import TheLeadIcon from '@/assets/sidebar-icons/TheLeadIcon.vue';
import UsersIcon from '@/assets/sidebar-icons/UsersIcon.vue';
import WorklistsIcon from '@/assets/sidebar-icons/WorklistsIcon.vue';
import OrgSettingsIcon from '@/assets/sidebar-icons/OrgSettingsIcon.vue';
import { useUsersStore } from '@/stores/useUsers';
import { STATS_SKUS } from '@/utils/constants';
import * as helpers from '@/utils/helpers';
import LoginView from '@/views/auth/LoginView.vue';
import LogoutView from '@/views/auth/LogoutView.vue';
import RegisterView from '@/views/auth/RegisterView.vue';
import DocumentView from '@/views/document/DocumentView.vue';
import LeadView from '@/views/lead/LeadView.vue';
import WorkItem from '@/views/lead/WorkItem.vue';
import StatsView from '@/views/stats/StatsView.vue';
import UsersView from '@/views/users/UsersView.vue';
import UserDetail from '@/views/users/UserDetail.vue';
import AdminPanelView from '@/views/admin-panel/AdminPanelView.vue';
import GamePlan from '@/views/refactor/GamePlan.vue';
import { Auth } from 'aws-amplify';
import { createRouter, createWebHistory } from 'vue-router';
import NotFoundPage from '@/views/common/NotFoundPage.vue';
import UpsertWorkListView from '@/views/refactor/UpsertWorkListView';
import { useGamePlanStore } from '@/stores/useGamePlans';

const publicRoutes = ['/login'];

async function isAuthenticated(to) {
  const authRequired = !publicRoutes.includes(to.path);
  try {
    // Throw an error if the user isn't authenticated
    await Auth.currentAuthenticatedUser();
    if (!authRequired) return true;

    // Check if current route is permissioned

    const currentRoute = routes.find(route => {
      return (
        route.path == to.path ||
        route.path == to.matched.find(e => e.name == route.name)?.path
      );
    });

    if (currentRoute.featureFlag != null) {
      const featureFlagPass = Boolean(
        process.env[currentRoute.featureFlag] == 'true'
      );
      if (!featureFlagPass) return { name: 'lead' };
    }

    if (currentRoute.permissionKey != null) {
      const hasPerm = await helpers.hasPermission(
        currentRoute.permissionKey,
        null,
        currentRoute.validSkus
      );

      if (hasPerm == true) return true;
      else return { name: 'lead' };
    }
  } catch (err) {
    console.warn(err);
    /* user not authenticated */
    if (authRequired) return { name: 'login' };
  }
}

export const routes = [
  {
    path: '/',
    redirect: '/lead',
  },
  {
    path: '/status',
    redirect: '/lead',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
  },
  {
    path: '/lead',
    name: 'lead',
    component: LeadView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: true,
    icon: <TheLeadIcon class="w-7 h-7" />,
    sidebarName: 'High Five',
  },
  {
    path: '/lead/:id',
    name: 'leadId',
    component: WorkItem,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
  },
  {
    path: '/document/:claim',
    name: 'document',
    component: DocumentView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
  },
  {
    path: '/gameplan',
    name: 'gameplan',
    component: GamePlan,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: true,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Game Plan',
    permissionKey: 'VIEW_GAME_PLAN_TAB',
  },
  {
    path: '/gameplan/new',
    name: 'newWorklist',
    component: UpsertWorkListView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: false,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Gameplan (Flagged)',
    permissionKey: 'VIEW_GAME_PLAN_TAB',
  },
  {
    path: '/gameplan/edit',
    name: 'editWorklist',
    component: UpsertWorkListView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: false,
    icon: <WorklistsIcon class="w-7 h-7" />,
    sidebarName: 'Gameplan (Flagged)',
    permissionKey: 'VIEW_GAME_PLAN_TAB',
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: true,
    icon: <UsersIcon class="w-7 h-7" />,
    sidebarName: 'Users',
    permissionKey: 'VIEW_USERS',
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: true,
    icon: <StatsIcon class="w-7 h-7" />,
    sidebarName: 'Stats',
    permissionKey: 'VIEW_STATS',
    validSkus: STATS_SKUS,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanelView,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    sidebar: true,
    icon: <OrgSettingsIcon />,
    sidebarName: 'Organization Settings',
    permissionKey: 'VIEW_ADMIN',
  },
  {
    path: '/users/:auxo_user_id',
    name: 'user',
    component: UserDetail,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    permissionKey: 'VIEW_USERS',
  },
  { path: '/register', name: 'register', component: RegisterView },
  {
    path: '/notfound',
    component: NotFoundPage,
    beforeEnter(to) {
      return isAuthenticated(to);
    },
  },
  {
    path: '/:catchAll(.*)',
    beforeEnter(to) {
      return isAuthenticated(to);
    },
    component: NotFoundPage,
  },
];

/**
 * Filter the routes by permission
 *
 * @param {*} sidebar - filter the routes if they are a sidebar or not
 * @returns - the routes filtered by permission
 */
export const getFilteredPages = async (sidebar = true) => {
  const users_store = useUsersStore();

  return Promise.all(
    routes
      ?.filter(route => route.sidebar == sidebar)
      .map(async route => {
        if (route.permissionKey != null) {
          const hasPerm = await users_store.hasPermission(
            route.permissionKey,
            null,
            route.validSkus
          );
          if (hasPerm !== true) return null;
        }

        if (route.featureFlag != null) {
          const featureFlagPass = Boolean(
            process.env[route.featureFlag] == 'true'
          );
          if (!featureFlagPass) return null;
        }

        return route;
      })
  ).then(routes => routes.filter(v => v));
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Route guard for Individual worklist Page
  const gameplan_store = useGamePlanStore();

  if (
    gameplan_store.editMode &&
    from.fullPath?.includes('gameplan') &&
    !to.fullPath?.includes('gameplan')
  ) {
    const confirm = window.confirm('Are you sure you want to leave this page?');
    if (confirm) {
      // User confirmed, allow the navigation
      gameplan_store.deleteDraftWorkStrategy().then(() => {
        next();
      });
    } else {
      // User canceled, prevent the navigation
      next(false);
    }
  } else {
    // User is not leaving the certain route, allow the navigation
    next();
  }
});
export default router;
