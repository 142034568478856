<template>
  <div class="flex flex-col">
    <div
      class="font-normal text-gray-900 overflow-auto whitespace-pre-wrap break-words"
    >
      <div v-html="formatLinks(props.text.body)"></div>
      <div v-if="props.text.linkUrl">
        [<a
          :href="props.text.linkUrl"
          target="_blank"
          class="underline text-blue-500"
        >
          Source </a
        >]
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  text: {
    // type: String,
    default: {
      body: {
        type: String,
        default: '',
      },
      imageUrl: {
        type: String,
        default: '',
      },
      linkUrl: {
        type: String,
        default: '',
      },
      linkText: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
  },
});
const formatLinks = text => {
  return text.replace(
    /<url>([^<]+)<\/url>/g,
    '<a href="$1" target="_blank" class="text-blue-500 underline">$1</a>'
  );
};
</script>
