<template>
  <div
    class="query-input flex justify-between"
    :class="{
      'ml-[1em]': props.level === 0,
      'ml-[2em]': props.level > 0,
    }"
  >
    <div class="flex justify-start text-sm w-full">
      <DropDown
        :disabled="gamePlanView"
        :model-value="query.field"
        dropdown-class="max-h-9"
        list-class="!top-[36px]"
        :class="'!mt-0 flex-1'"
        :options="props.fields"
        :error="queryBuilder.attempt && !query.field"
        placeholder="Field"
        :change="
          e => {
            Object.assign(query, {
              ...query,
              field: e.key,
              type: e.rest.dtype,
              input: null,
              operator: null,
              value: null,
            });
          }
        "
      />

      <DropDown
        :model-value="query.operator"
        dropdown-class="max-h-9"
        list-class="!top-[36px]"
        :class="'!mt-0 flex-1 ml-2'"
        :options="queryBuilder.getOperators(query.field)"
        :disabled="query.field === null || gamePlanView"
        :error="queryBuilder.attempt && !query.operator"
        placeholder="Operator"
        :change="
          e => {
            Object.assign(query, {
              ...query,
              operator: e.key,
              input: e.rest.input,
              value: null,
            });
          }
        "
      />
      <DynamicValueInput
        :level="props.level"
        :key-chain="props.keyChain"
        :index-chain="props.indexChain"
        :rule="query"
        :placeholder="'Value'"
        :change="
          e => {
            query.value = e.key;
          }
        "
        :game-plan-view="gamePlanView"
        :parent-rule="props.parentRule"
      />
    </div>
    <DeleteButton
      v-show="!gamePlanView"
      :level="props.level"
      :key-chain="props.keyChain"
      :index-chain="props.indexChain"
    />
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue';
import DropDown from '@/components/forms/DropDown.vue';
import DeleteButton from './DeleteButton.vue';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import DynamicValueInput from './DynamicValueInput.vue';
import './QueryBuilder.css';

const queryBuilder = useQueryBuilderStore();

/* State */
const query = reactive({
  id: props.keyChain[props.level],
  field: null,
  type: null,
  input: null,
  operator: null,
  value: null,
});

watch(query, async (_, newQuery) => {
  if (newQuery.value?.length == 0) newQuery.value = null;
  queryBuilder.updateAttributes(props.level, props.indexChain, newQuery);
});

onMounted(() => {
  const { field, type, input, operator, value } = queryBuilder.getAttributes(
    props.level,
    props.indexChain
  );

  query.field = field;
  query.type = type;
  query.input = input;
  query.operator = operator;
  query.value = value;
});

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  keyChain: {
    type: Array,
    default: () => [],
  },
  indexChain: {
    type: Array,
    default: () => [],
  },
  fields: {
    type: Array,
    default: () => [],
  },
  gamePlanView: {
    type: Boolean,
    default: false,
  },
  parentRule: {
    type: Object,
    default: () => {},
  },
});
</script>

<style scoped></style>
