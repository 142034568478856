<template>
  <div
    class="w-full h-full bg-no-repeat bg-cover flex items-center justify-center bg-primary-100"
    :style="{ backgroundImage: `url(${bgImage})` }"
  >
    <div class="flex w-2/3 h-2/3 rounded-lg bg-white">
      <!-- Logo Container -->
      <div class="flex flex-1 items-center px-3 py-1 bg-primary rounded-lg">
        <img :src="logoImage" class="w-full px-8" />
      </div>

      <!-- Form Container -->
      <div
        class="flex flex-col flex-1 justify-center items-center text-center bg-white rounded-lg"
      >
        <div class="w-5/6">
          <p class="text-2xl text-primary w-full my-3 font-bold">Register</p>
          <p class="text-xs text-gray-400 w-full">
            We're happy you're here! Please register below
          </p>

          <!-- Email Form -->
          <form
            v-if="!showConfirmationInput"
            id="emailForm"
            class="my-10 text-left text-xs text-gray-400"
            :onsubmit="handleSubmit"
          >
            <label class="font-bold p-0.5">Email</label>
            <input
              v-model="email"
              class="border-2 hover:border-2 w-full rounded p-2 text-xs my-2"
              autofocus
            />

            <label class="font-bold p-0.5">Password</label>
            <input
              v-model="password"
              class="border-2 hover:border-2 w-full rounded p-2 my-2 text-xs"
              type="password"
            />

            <label class="font-bold p-0.5">Confirm Password</label>
            <input
              v-model="passwordConfirm"
              class="border-2 hover:border-2 w-full rounded p-2 text-xs"
              type="password"
            />

            <button
              type="submit"
              class="text-white py-2 my-3 w-full px-5 rounded bg-primary"
            >
              Register
            </button>
          </form>

          <form
            v-if="showConfirmationInput"
            id="emailForm"
            class="my-10 text-left text-xs text-gray-400"
            :onsubmit="handleConfirmation"
          >
            <label class="font-bold p-0.5">Confirmation Code</label>
            <input
              v-model="confirmationCode"
              class="border-2 hover:border-2 w-full rounded p-2 text-xs"
            />
            <button
              type="submit"
              class="text-white py-2 my-3 w-full px-5 rounded bg-primary"
            >
              Confirm Account
            </button>
          </form>
          <p class="text-xs text-red-400">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { ref } from 'vue';
import { Auth } from 'aws-amplify';
import bgImage from '@/assets/login-background.png';
import logoImage from '@/assets/auxo-White-3C.png';

const email = ref('');
const password = ref('');
const passwordConfirm = ref('');
const errorMessage = ref('');
const showConfirmationInput = ref(false);
const confirmationCode = ref('');

function handleConfirmation(e) {
  e.preventDefault();

  confirmEmail();
}

function handleSubmit(e) {
  e.preventDefault();

  if (password.value !== passwordConfirm.value) {
    errorMessage.value = 'Passwords do not match';
  } else {
    errorMessage.value = '';
    signUp();
  }
}

async function confirmEmail() {
  try {
    await Auth.confirmSignUp(email.value, confirmationCode.value);
    confirmationCode.value = '';
    router.push('/login');
  } catch (error) {
    console.log('error confirming sign up', error);
    showConfirmationInput.value = false;
  }
}

async function signUp() {
  if (password.value !== passwordConfirm.value) {
    errorMessage.value = 'Passwords do not match';
  } else {
    errorMessage.value = '';
    try {
      await Auth.signUp({
        username: email.value,
        password: password.value,
        attributes: { email: email.value, given_name: '', family_name: '' },
        autoSignIn: {
          enabled: true,
        },
      });
      showConfirmationInput.value = true;
    } catch (error) {
      errorMessage.value = error;
    }
  }
}
</script>
