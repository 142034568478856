import { defineStore } from 'pinia';
import { getAuthenticatedHeaders } from '@/utils/auth';

export const useDashboardsStore = defineStore('dashboards', {
  state: () => ({
    dashboards: [],
    isLoading: true,
  }),
  getters: {},
  actions: {
    async fetchGeneratedDashboardUrls() {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_DASHBOARDS_API_GATEWAY_ENDPOINT}dashboards/url/`,
          {
            headers: headers.headers,
          }
        );
        const data = await response.json();
        this.dashboards = data.dashboards;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
