import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import { getAuthenticatedHeaders } from '@/utils/auth';
import { CONDITIONALS, EMPTY_INPUT } from '@/views/query-builder/constants';
import { WORKLIST_ATTRIBUTES } from '../views/query-builder/constants';
import getDynamicPath from '@/utils/getDynamicPath';
import { useGamePlanStore } from './useGamePlans';
import { toast } from 'vue3-toastify';
import { isBefore } from 'date-fns';

export const DEFAULT_WORKLIST = {
  work_list_name: '',
  owner: '',
  assignees: [],
  is_priority: false,
  description: '',
  start_timest: null,
  end_timest: null,
};

export const DEFAULT_QUERY = {
  condition: 'AND',
  rules: [],
  valid: false,
};

export const useQueryBuilderStore = defineStore('queryBuilder', {
  state: () => {
    return {
      worklist: {
        ...DEFAULT_WORKLIST,
      },
      query: {
        ...DEFAULT_QUERY,
      },
      field_options: [],
      attempt: false,
      defaultWorklist: false,
      disabledDuringAttempt: false,
    };
  },
  getters: {
    getAttempt: state => {
      return state.attempt;
    },
    getQuery: state => {
      return state.query;
    },
    getWorklist: state => {
      return state.worklist;
    },
    getFields: () => {
      return Object.values(WORKLIST_ATTRIBUTES)
        .map(field => ({
          value: field.label,
          ...field,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    getOperators: () => {
      return field => {
        const attribute = WORKLIST_ATTRIBUTES[field];
        if (!attribute) return [];
        return WORKLIST_ATTRIBUTES[field].operators.map(operator => ({
          value: operator.label,
          ...attribute,
          ...operator,
        }));
      };
    },
    getAttributes: state => {
      return (level, indexChain) => {
        if (level === 0) {
          return state.query.rules[indexChain[0]];
        } else if (level === 1) {
          return state.query.rules[indexChain[0]].rules[indexChain[1]];
        } else if (level === 2) {
          return state.query.rules[indexChain[0]].rules[indexChain[1]].rules[
            indexChain[2]
          ];
        } else {
          console.error('Not Supported');
        }
      };
    },
  },
  actions: {
    setAttempt(val) {
      this.attempt = val;
    },
    setDisabledDuringAttempt(val) {
      this.disabledDuringAttempt = val;
    },
    setValidity(isValid) {
      this.query.valid = isValid;
    },
    validateRules() {
      if (this.defaultWorklist) {
        this.query = {
          ...DEFAULT_QUERY,
          valid: true,
        };
        return true;
      } else if (this.query.rules?.length == 0) {
        this.setValidity(false);
        return false;
      }

      // write logic to go through and check if the schema generated is valid
      const { query } = this;
      // get all the rules
      const initial_rules = query.rules;
      const all_the_rules = [];
      // flatten them for comparison
      function flatten_rules(rules) {
        for (const rule of rules) {
          if (rule.id) all_the_rules.push(rule);
          else flatten_rules(rule.rules);
        }
      }
      flatten_rules(initial_rules);

      // go through and check there's a field
      let is_valid_schema = true;

      // allowed nulls
      const allowed_nulls = ['is_null', 'is_not_null'];

      for (const rule of all_the_rules) {
        if (!rule.field || !rule.operator) {
          //if there are empties in the field or operator, it's not valid
          is_valid_schema = false;
          break;
        }
        // get the rule depending on type or if it is null
        let value;
        if (rule?.value === null) {
          value = false;
        } else if (typeof rule?.value === 'string') {
          value = rule.value.trim();
        } else if (typeof rule?.value === 'object') {
          value = [...Object.values(rule?.value)];
        }

        if (
          // if there's no value and the operator isn't in allowed_nulls
          !value &&
          !allowed_nulls.includes(rule.operator)
        ) {
          is_valid_schema = false;
          break;
        }

        // if the object is an array and has no elements
        if (Array.isArray(value) && !value.length) {
          is_valid_schema = false;
          break;
        }
        // the object is an array and has unallowed null value(s)
        if (Array.isArray(value) && value.includes(null)) {
          is_valid_schema = false;
          break;
        }
        if (
          rule.type === 'date' &&
          Array.isArray(value) &&
          rule.value.length > 1 &&
          !isBefore(new Date(rule.value[0]), new Date(rule.value[1]))
        ) {
          toast.error('Dates must be in ascending order!');
          is_valid_schema = false;
          break;
        }
        if (
          (rule.operator === 'between' || rule.operator === 'not_between') &&
          rule.value[1] - rule.value[0] <= 0
        ) {
          toast.error(`Second value must be greater than ${rule.value[0]}`, {
            toastId: 'min-max-error',
          });
          is_valid_schema = false;
          break;
        }
      }
      this.setValidity(is_valid_schema);
      return is_valid_schema;
    },
    validateWorklist(editingWorklist) {
      // Check for required inputs

      if (
        !this.defaultWorklist &&
        (!this.worklist.work_list_name?.trim()?.length ||
          !this.worklist.description?.trim()?.length ||
          (this.worklist.is_priority && !this.worklist.end_timest))
      )
        return false;

      const gameplan_store = useGamePlanStore();
      // Check for existing worklist name on new worklists
      if (editingWorklist == false) {
        // Get a list of worklist that have the same name as the edit
        const matchingNames = gameplan_store.previous_worklists.filter(wl => {
          return (
            wl.work_list_name.toLowerCase()?.trim() ==
            this.worklist.work_list_name?.toLowerCase()?.trim()
          );
        });

        if (!matchingNames.length) return true;

        const unExpiredMatchingName = matchingNames.some(worklist => {
          return (
            new Date(worklist.end_timest).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0) || !worklist.end_timest
          );
        });

        if (unExpiredMatchingName) {
          toast.error('Existing worklist name found.');
          return false;
        }
      }

      // Check for existing worklist name on editing worklists
      else if (editingWorklist == true) {
        // Get a list of worklist that have the same name as the edit
        const matchingNames = gameplan_store.previous_worklists.filter(wl => {
          // If you are the same wl, you can have the same name
          if (wl.work_list_id == this.worklist?.work_list_id) {
            return false;
          } else
            return (
              wl.work_list_name.toLowerCase()?.trim() ==
              this.worklist.work_list_name?.toLowerCase()?.trim()
            );
        });

        if (!matchingNames.length) return true;

        const unExpiredMatchingName = matchingNames.some(worklist => {
          return (
            new Date(worklist.end_timest).setHours(0, 0, 0, 0) >=
              new Date().setHours(0, 0, 0, 0) || !worklist.end_timest
          );
        });

        if (unExpiredMatchingName) {
          toast.error('Existing worklist name found.');
          return false;
        }
      }

      return true;
    },
    updateWorklist(value) {
      this.worklist = {
        ...this.worklist,
        ...value,
      };
    },
    setConditional(condition, level, indexChain) {
      if (level === 0) {
        this.query = {
          ...this.query,
          condition,
        };
      } else if (level === 1) {
        const updatedRuleGroup = {
          ...this.query.rules[indexChain[0]],
          condition,
        };

        this.query.rules[indexChain[0]] = updatedRuleGroup;
      } else if (level === 2) {
        const updatedRuleGroup = {
          ...this.query.rules[indexChain[0]].rules[indexChain[1]],
          condition,
        };

        this.query.rules[indexChain[0]].rules[indexChain[1]] = updatedRuleGroup;
      } else {
        console.error('Not supported.');
      }
    },
    updateAttribute(event, level, indexChain, attributeName) {
      if (level === 0) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]],
          [attributeName]: event.key,
        };

        this.query.rules[indexChain[0]] = updatedObject;
      } else if (level === 1) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]].rules[indexChain[1]],
          [attributeName]: event.key,
        };

        this.query.rules[indexChain[0]].rules[indexChain[1]] = updatedObject;
      } else if (level === 2) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]].rules[indexChain[1]].rules[
            indexChain[2]
          ],
          [attributeName]: event.key,
        };

        this.query.rules[indexChain[0]].rules[indexChain[1]].rules[
          indexChain[2]
        ] = updatedObject;
      } else {
        console.error('Not Supported');
      }
    },
    updateAttributes(level, indexChain, object) {
      if (level === 0) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]],
          ...object,
        };

        this.query.rules[indexChain[0]] = updatedObject;
      } else if (level === 1) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]].rules[indexChain[1]],
          ...object,
        };

        this.query.rules[indexChain[0]].rules[indexChain[1]] = updatedObject;
      } else if (level === 2) {
        const updatedObject = {
          ...this.query.rules[indexChain[0]].rules[indexChain[1]].rules[
            indexChain[2]
          ],
          ...object,
        };

        this.query.rules[indexChain[0]].rules[indexChain[1]].rules[
          indexChain[2]
        ] = updatedObject;
      } else {
        console.error('Not Supported');
      }
    },
    async getFieldValues(path) {
      const headers = await getAuthenticatedHeaders();
      try {
        const response = await API.get(
          'insurance',
          getDynamicPath(`lookups/workItemTypes/${path}`),
          headers
        );

        const { data: responseData } = response;

        this.field_options = responseData.data;

        if (!responseData.success) {
          console.error('field value error');
        } else {
          return responseData.data;
        }
      } catch (err) {
        console.error(err.response ?? err);
        return err.response ?? err;
      }
    },
    resetWorklist() {
      console.debug(`Resetting worklist`);
      this.worklist = { ...DEFAULT_WORKLIST };
    },
    resetQuery() {
      console.debug(`Resetting query.`);
      this.query = { ...DEFAULT_QUERY };
    },
    setRulesSchema(rules) {
      this.query = rules;
    },
    addRule(level, indexChain, keyChain, newKey) {
      console.debug(
        `Creating rule at level ${level} at path ${
          ' > ' + keyChain?.filter(e => e)?.join(' > ')
        } with key ${newKey}.`
      );

      if (level === 0) {
        this.query.rules = [
          ...this.query.rules,
          {
            id: newKey,
            ...EMPTY_INPUT,
          },
        ];
      } else if (level === 1) {
        this.query.rules[indexChain[0]].rules = [
          ...this.query.rules[indexChain[0]].rules,
          {
            id: newKey,
            ...EMPTY_INPUT,
          },
        ];
      } else if (level === 2) {
        this.query.rules[indexChain[0]].rules[indexChain[1]].rules = [
          ...this.query.rules[indexChain[0]].rules[indexChain[1]].rules,
          {
            id: newKey,
            ...EMPTY_INPUT,
          },
        ];
      } else {
        console.error('Not supported.');
      }
    },
    addGroup(level, indexChain, keyChain, newKey) {
      console.debug(
        `Creating group at level ${level} at path ${
          ' > ' + keyChain?.filter(e => e)?.join(' > ')
        } with key ${newKey}.`
      );

      if (level === 0) {
        this.query.rules = [
          ...this.query.rules,
          {
            condition: CONDITIONALS.AND,
            rules: [
              {
                id: newKey,
                ...EMPTY_INPUT,
              },
            ],
          },
        ];
      } else if (level === 1) {
        this.query.rules[indexChain[0]].rules = [
          ...this.query.rules[indexChain[0]].rules,
          {
            condition: CONDITIONALS.AND,
            rules: [
              {
                id: newKey,
                ...EMPTY_INPUT,
              },
            ],
          },
        ];
      } else {
        console.error('Not Supported.');
      }
    },
    remove(level, indexChain, keyChain, isGroup) {
      console.debug(
        `Removing at level ${level} at path ${` > ${keyChain
          ?.filter(e => e)
          ?.join(' > ')}`}.`
      );

      if (level === 0) {
        this.query.rules.splice(indexChain[0], 1);
      } else if (level === 1) {
        if (isGroup || this.query.rules[indexChain[0]].rules.length === 1)
          this.query.rules.splice(indexChain[0], 1);
        else {
          this.query.rules[indexChain[0]].rules.splice(indexChain[1], 1);
        }
      } else if (level === 2) {
        if (
          isGroup ||
          this.query.rules[indexChain[0]].rules[indexChain[1]].rules.length ===
            1
        ) {
          if (this.query.rules[indexChain[0]].rules.length === 1) {
            this.query.rules.splice(indexChain[0], 1);
          } else {
            this.query.rules[indexChain[0]].rules.splice(indexChain[1], 1);
          }
        } else {
          this.query.rules[indexChain[0]].rules[indexChain[1]].rules.splice(
            indexChain[2],
            1
          );
        }
      } else {
        console.error('Not supported.');
      }
    },
  },
});
