import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import getDynamicPath from '../utils/getDynamicPath';
import { getAuthenticatedHeaders } from '@/utils/auth';
import * as helpers from '@/utils/helpers';

export const useAdminStore = defineStore('admin', {
  state: () => ({
    users: [],
    filteredUsers: [],
    existingEmails: [],
    searchCriteria: '',
    usersLoading: false,
    selectedUser: {},
    modalAction: '',
    tableViewFilter: true,
  }),
  getters: {
    sortUsers: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.filteredUsers);
      };
    },
    getFilteredUsers: state => criteria => {
      if (criteria == '') {
        state.filteredUsers = state.users;
        return state.users;
      }

      if (state.usersLoading) return [];

      const lowerSearchCriteria = criteria.toLowerCase();
      let role = '';

      const roleMapping = {
        leader: 'MGR',
        admin: 'ADM',
        contributor: 'REP',
      };

      for (const key in roleMapping) {
        if (
          lowerSearchCriteria.includes(key) ||
          key.includes(lowerSearchCriteria)
        ) {
          role = roleMapping[key];
          break;
        }
      }
      state.filteredUsers = state.users.filter(user => {
        return (
          user.full_name.toLowerCase().includes(lowerSearchCriteria) ||
          user.email.toLowerCase().includes(lowerSearchCriteria) ||
          user.user_roles.includes(role)
        );
      });
      return state.filteredUsers;
    },
    getUserById: state => {
      return userId => {
        const user = state.users.find(
          user => user.user_id || user.auxo_user_id === userId
        );
        return {
          ...user,
          initials: helpers.getUserInitials(user),
        };
      };
    },
  },
  actions: {
    async getUsers(is_active) {
      this.usersLoading = true;
      const headers = await getAuthenticatedHeaders();
      let adjustedHeaders = {
        ...headers,
        queryStringParameters: {
          is_active: is_active,
        },
      };
      try {
        const { data: response } = await API.get(
          'insurance',
          getDynamicPath('users'),
          adjustedHeaders
        );
        this.users = response.data
          .map(user => {
            user.photo_url = user.photo_url || 'none';
            const full_name = [user?.first_name, user?.last_name]
              .filter(e => e)
              .join(' ');
            return {
              ...user,
              username: helpers.getUsername(user.email),
              full_name,
              informal_title: helpers.formatUserRoles(user?.user_roles),
              label: full_name,
            };
          })
          .sort((a, b) => {
            return a.first_name.localeCompare(b.first_name, 'en', {
              sensitivity: 'accent',
            });
          });
      } catch (err) {
        console.error(err);
      } finally {
        this.usersLoading = false;
        this.filteredUsers = this.getFilteredUsers(this.searchCriteria);
      }
    },
    async fetchExistingEmails() {
      const headers = await getAuthenticatedHeaders();
      let adjustedHeaders = {
        ...headers,
        queryStringParameters: {
          is_active: null,
        },
      };
      try {
        const { data: response } = await API.get(
          'insurance',
          getDynamicPath('users'),
          adjustedHeaders
        );
        this.existingEmails = response.data.map(user => user.email);
      } catch (err) {
        console.error(err);
      }
    },
    async createUser(firstName, lastName, email, photo = null, roles) {
      const headers = await getAuthenticatedHeaders();
      const payload = {
        body: {
          firstName,
          lastName,
          email,
          photo,
          roles,
        },
        ...headers,
      };
      try {
        const { data: response } = await API.post(
          'insurance',
          getDynamicPath(`users`),
          payload
        );

        if (response.status_code === 200) {
          const { data } = response;
          return {
            ...data,
          };
        } else {
          throw Error(`Could not create new auxo user.`);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateUser(
      auxo_user_id,
      firstName,
      lastName,
      email,
      photo,
      is_active,
      roles
    ) {
      const headers = await getAuthenticatedHeaders();
      const payload = {
        body: {
          firstName,
          lastName,
          email,
          photo,
          is_active,
          roles,
        },
        ...headers,
      };
      try {
        const { data: response } = await API.put(
          'insurance',
          getDynamicPath(`users/${auxo_user_id}`),
          payload
        );

        if (response.status_code === 200) {
          const { data } = response;
          return {
            ...data,
          };
        } else {
          throw Error(`Could not create new auxo user.`);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async uploadAvatar(imageData) {
      const headers = await getAuthenticatedHeaders();
      const payload = {
        body: { imageData },
        ...headers,
      };
      try {
        const { data: response } = await API.put(
          'insurance',
          getDynamicPath(`users/avatar`),
          payload
        );

        if (response.status_code === 200) {
          const { data } = response;
          // Return the url from s3
          return data;
        } else {
          throw Error(`Avatar upload failed.`);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
});
