import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import getDynamicPath from '../utils/getDynamicPath';
import { getAuthenticatedHeaders } from '@/utils/auth';

export const useSearchStore = defineStore('Search', {
  state: () => ({
    searchResults: [],
    isLoading: true,
    savedSearchQuery: null,
    savedQueryResults: null,
    showExpandedSearch: false,
    showSearchModal: false,
    isSearching: false,
    justSearched: false,
    input: '',
    searchRecords: 0,
    searchPage: 1,
    searchOffset: 0,
    searchLimit: 5,
    searchDirection: 'ASC',
    searchField: 'patient_name',
  }),
  getters: {},
  actions: {
    setInput(val) {
      this.input = val;
    },
    setJustSearched(val) {
      this.justSearched = val;
    },
    setSearchResults(results) {
      this.searchResults = results;
    },
    setSearchQuery(query) {
      this.savedSearchQuery = query;
    },
    setQueryResults(results) {
      this.savedQueryResults = results;
    },
    setShowExpandedSearch(val) {
      this.showExpandedSearch = val;
    },
    setShowSearchModal(val) {
      this.showSearchModal = val;
    },
    setIsSearching(val) {
      this.isSearching = val;
    },
    clear() {
      this.searchResults = [];
      this.isLoading = true;
      this.savedSearchQuery = null;
      this.savedQueryResults = null;
      this.showExpandedSearch = false;
      this.input = '';
      this.searchPage = 1;
      this.searchDirection = 'ASC';
      this.searchField = 'patient_name';
    },
    close() {
      this.searchResults = [];
      this.isLoading = true;
      this.savedSearchQuery = null;
      this.savedQueryResults = null;
      this.showExpandedSearch = false;
      this.input = '';
      this.searchPage = 1;
      this.searchDirection = 'ASC';
      this.searchField = 'patient_name';
      this.showSearchModal = false;
    },
    async queryBySearchTerm(
      query,
      page = 1,
      direction = 'ASC',
      field = 'patient_name'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.searchPage = page;
      this.searchDirection = direction.toUpperCase();
      this.searchField = field;
      this.isLoading = true;
      direction = direction.toUpperCase();
      const localStorageLimit = await localStorage.getItem('search_pagination');
      const limit = localStorageLimit
        ? parseInt(localStorageLimit)
        : this.searchLimit;
      this.searchLimit = limit;
      const payload = {
        body: {
          query,
          limit,
          offset: page ? (page - 1) * limit : this.searchOffset,
          direction,
          field,
        },
        ...headers,
      };

      try {
        const response = await API.post(
          'insurance',
          getDynamicPath(`workItems/query`),
          payload
        );

        this.searchResults = response?.data?.data?.rows || [];
        this.searchRecords = response?.data?.data?.records || 0;
        this.isLoading = false;
        return this.searchResults;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
  },
});
