/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
console.debug(process.env.VUE_APP_COGNITO_POOL_ID)
console.debug(process.env.VUE_APP_COGNITO_POOL_CLIENT_ID)
console.debug(process.env.VUE_APP_COGNITO_POOL_APP_SCOPE)
console.debug(process.env.VUE_APP_COGNITO_POOL_DOMAIN)
console.debug(process.env.VUE_APP_COGNITO_POOL_APP_RESPONSE_TYPE)
console.debug(process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_IN)
console.debug(process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_OUT)
console.debug(process.env.VUE_APP_WLIST_FF)

const awsmobile = {
    "aws_project_region": process.env.VUE_APP_AWS_REGION,
    "aws_cognito_region": process.env.VUE_APP_AWS_REGION,
    "aws_user_pools_id": process.env.VUE_APP_COGNITO_POOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_COGNITO_POOL_CLIENT_ID,
    "oauth": {
        "scope": process.env.VUE_APP_COGNITO_POOL_APP_SCOPE.split(' '),
        "domain": process.env.VUE_APP_COGNITO_POOL_DOMAIN,
        "responseType": process.env.VUE_APP_COGNITO_POOL_APP_RESPONSE_TYPE,
        "redirectSignIn": process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_IN,
        "redirectSignOut": process.env.VUE_APP_COGNITO_POOL_REDIRECT_SIGN_OUT,
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "dashboards",
            "endpoint": process.env.VUE_APP_DASHBOARDS_API_GATEWAY_ENDPOINT,
            "region": process.env.VUE_APP_AWS_REGION
        },
        {
            "name": "insurance",
            "endpoint": process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT,
            "region": process.env.VUE_APP_AWS_REGION
        }
    ],
    Auth: {
        storage: sessionStorage,
      },
};


export default awsmobile;
