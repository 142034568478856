import { Auth } from 'aws-amplify';

export async function getAuthenticatedHeaders() {
  const user = await Auth.currentSession();
  const token = user.getIdToken().getJwtToken();
  const reqData = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    response: true,
  };
  return reqData;
}
