<template>
  <div
    class="flex flex-col relative mt-1.5"
    :class="{
      [props.class]: true,
    }"
  >
    <label :for="id" :class="labelClass ? labelClass : 'text-gray-500'">{{
      label
    }}</label>
    <div
      class="flex w-full border-2 p-1 rounded outline-1 items-center max-h-8"
      :class="
        dropdownClass
          ? {
              [dropdownClass]: true,
              'border-red-600': props.error,
              'border-black': isOpen,
              'bg-white': !props.disabled,
              'bg-gray-50': props.disabled,
            }
          : {
              'border-red-600': props.error,
              'border-black': isOpen,
              'bg-white': !props.disabled,
              'bg-gray-50': props.disabled,
            }
      "
    >
      <input
        :id="id"
        v-model="attempt"
        class="w-full p-1 outline-0 dropdown text-rev-sm text-gray-700"
        :class="{
          'bg-gray-50': props.disabled,
        }"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        @keydown="handleKeyboardInput"
        @click="
          () => {
            isOpen = !isOpen;
          }
        "
      />
      <div
        v-show="hideClearButton()"
        ref="clearButtonRef"
        class="cursor-pointer"
        @click="
          () => {
            selectOption({ value: null, key: null }, true);
          }
        "
      >
        <img :src="CloseIconSVG" class="w-4 h-4 mr-2" />
      </div>

      <div
        :class="props.disabled ? 'none' : 'cursor-pointer'"
        @click="toggleDropdown"
      >
        <img
          :src="DownArrowSVG"
          class="h-3 mr-1"
          :class="{
            'rotate-180': isOpen,
          }"
        />
      </div>
    </div>
    <ul
      v-if="isOpen"
      ref="dropdownRef"
      class="text-black bg-gray-200 w-full h-fit absolute top-[50px] rounded max-h-[300px] custom-scroll overflow-y-auto z-20"
      :class="props.listClass"
    >
      <!-- Show no options label always, when there is no options -->
      <li
        v-if="options.length == 0 || findMatches(attempt)?.length == 0"
        key="No Options"
        class="p-2 text-primary border-b border-primary-100 last:border-none cursor-not-allowed"
      >
        No Options
      </li>

      <li
        v-for="option in findMatches(attempt)"
        :id="getOptionId(option)"
        :key="option.key"
        class="p-2 cursor-pointer border-b border-primary-100 last:border-none hover:bg-primary-100"
        :class="{
          'bg-primary-100': option.key === focusedElement,
          'text-primary': !props.listClass,
        }"
        @click="selectOption(option)"
      >
        {{ option.value }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import isEqual from 'lodash/isEqual';
import { onClickOutside } from '@vueuse/core';
import DownArrowSVG from '@/assets/down-arrow.svg';
import CloseIconSVG from '@/assets/close-icon.svg';

const emits = defineEmits(['update:modelValue']);
const dropdownRef = ref(null);
const clearButtonRef = ref(null);
const isOpen = ref(false);
const focusedElement = ref(null);
const attempt = ref('');
const submission = ref(false);
const copying = ref(false);
const mac_command_key = 91;
const windows_control_key = 17;

const id = `dropdown-${Date.now()}`;

// Get reasonable id for list options
const getOptionId = option => {
  const { key, value } = option;
  return `${key}-${value.split(' ').join('-')}`;
};

// Listen for keystrokes, moving focusing and selecting input
const handleKeyboardInput = e => {
  // handle copy + pasting to not trigger anything
  if (copying.value && (e.key === 'a' || e.key === 'c' || e.key === 'v')) {
    copying.value = false;
    return;
  }
  if (e.keyCode == mac_command_key || e.keyCode == windows_control_key) {
    copying.value = true;
    return;
  }

  if (e.key === 'ArrowDown') {
    const currentList = findMatches(attempt.value);

    if (currentList.length == 0) {
      isOpen.value = true;
      return;
    }

    if (focusedElement.value) {
      const currentIndex = currentList.findIndex(
        e => e.key === focusedElement.value
      );

      if (currentIndex + 1 > currentList.length - 1) return;

      const currentOption = currentList[currentIndex + 1];
      focusedElement.value = currentOption.key;

      document.getElementById(getOptionId(currentOption)).scrollIntoView();
    } else {
      focusedElement.value = currentList[0].key;
    }

    isOpen.value = true;
  } else if (e.key === 'ArrowUp') {
    const currentList = findMatches(attempt.value);

    if (focusedElement.value) {
      const currentIndex = currentList.findIndex(
        e => e.key === focusedElement.value
      );

      if (currentIndex - 1 < 0) return;

      const currentOption = currentList[currentIndex - 1];
      focusedElement.value = currentOption.key;

      document.getElementById(getOptionId(currentOption)).scrollIntoView();
    }
  } else if (e.key === 'Enter') {
    e.preventDefault();

    try {
      const { value, key, ...rest } = findMatches(attempt.value).find(
        e => e.key === focusedElement.value
      );
      update(key, value, rest);
    } catch (e) {
      console.error('No matches found.');
    }
  } else if (e.key === 'Tab') {
    // If there is not a valid selection, clear the attempt
    if (submission.value != true) {
      attempt.value = null;
      isOpen.value = false;
      submission.value = true;
      focusedElement.value = null;
    } else {
      isOpen.value = false;
      submission.value = false;
      focusedElement.value = null;
    }
  } else {
    isOpen.value = true;
    submission.value = false;
    focusedElement.value = null;
  }
};

const selectOption = (option, keepOpen = false) => {
  const { value, key, ...rest } = option;
  update(key, value, rest, keepOpen);
};

const update = (key, value, rest, keepOpen) => {
  attempt.value = value;
  submission.value = !!value;
  emits('update:modelValue', key ? String(key) : null);
  isOpen.value = keepOpen;
  focusedElement.value = null;

  if (props.change)
    props.change({
      key,
      value,
      rest,
    });
};

// Hides the clear button when Dropdown is disabled,
// or when there is not a value to clear
const hideClearButton = () => {
  if (props.disabled) return false;
  return Array.isArray(props.modelValue)
    ? props.modelValue?.length
    : props.modelValue != null;
};

// Clear the select input value if the options change
// and the value is no longer valid

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    // Options changed
    if (!isEqual(newOptions, oldOptions)) {
      // Is existing option still valid
      if (!newOptions.find(el => el.key == props.modelValue)) {
        emits('update:modelValue', null);
      }
    }
  }
);
// Clear the input when passed an empty value
watch(
  () => props.modelValue,
  newModelValue => {
    if (!newModelValue) {
      focusedElement.value = null;
      attempt.value = null;
    }
  }
);

// Auto focus to element if already selected
watch(isOpen, newIsOpenValue => {
  if (newIsOpenValue == true && props.modelValue && attempt.value) {
    nextTick(() => {
      const optionToFocus = document.getElementById(
        getOptionId({
          key: props.modelValue,
          value: attempt.value,
        })
      );
      focusedElement.value = props.modelValue;
      optionToFocus.scrollIntoView({
        behavior: 'instant',
        block: 'end',
        inline: 'nearest',
      });
    });
  }
});

// Watch to make sure the value passed in is appearing on the client side
watch(props, props => {
  if (props.modelValue && props.options.length) {
    let value;

    if (props.equalityFunction)
      value = props.options.find(e =>
        props.equalityFunction(e, props.modelValue)
      )?.value;
    else value = props.options.find(e => e.key == props.modelValue)?.value;
    attempt.value = value;
    submission.value = !!value;
    isOpen.value = false;
    focusedElement.value = null;
  }
});

onClickOutside(
  dropdownRef,
  e => {
    e.stopPropagation();
    isOpen.value = false;
    if (!submission.value) attempt.value = null;
  },
  {
    ignore: [clearButtonRef],
  }
);

const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  modelValue: {
    default: null,
    type: null, // Any type
  },
  options: {
    // object/array defaults should be returned from a factory function
    default() {
      return [];
    },
    type: Array,
  },
  placeholder: {
    default: '',
    type: String,
  },
  class: {
    default: '',
    type: String,
  },
  dropdownClass: {
    default: '',
    type: String,
  },
  labelClass: {
    default: 'font-bold',
    type: String,
  },
  change: {
    type: Function,
    default: () => 0,
  },
  listClass: {
    default: '',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  error: {
    default: false,
    type: Boolean,
  },
  multiSelect: {
    default: false,
    type: Boolean,
  },
  equalityFunction: {
    type: Function,
    default: null,
  },
});

const toggleDropdown = () => {
  if (props.disabled) return;
  isOpen.value = !isOpen.value;
};

function findMatches(v) {
  if (!v || submission.value == true) return props.options;
  const matches = props.options.filter(option =>
    option?.value
      ?.toLowerCase()
      .trim()
      .includes(v?.toLowerCase().trim() || null)
  );
  return matches;
}
</script>

<style scoped>
.custom-scroll {
  @apply scrollbar scrollbar-thumb-primary scrollbar-track-transparent scrollbar-thumb-rounded-xl scrollbar-track-rounded-xl scrollbar-thin;
}
</style>
