<template>
  <button
    v-if="!showBot"
    class="absolute right-4 bottom-4 z-10 p-3 py-3 rounded-full bg-primary border-4 border-green-300 expandable-button animate-pulse-colors"
    @click="openChatBot"
  >
    <div class="flex gap-2">
      <img :src="AuxoIcon" class="w-6 h-6" />
    </div>
  </button>
  <div
    v-if="showBot"
    class="mr-[-1.75rem] my-[-1.75rem] min-w-[350px] max-w-[350px]"
  >
    <div
      class="absolute flex flex-col border p-4 bg-white min-w-[350px] max-w-[350px] h-[93vh] right-0 z-50"
    >
      <div class="flex justify-between items-center">
        <div class="flex items-center gap-3">
          <div
            class="p-2 rounded-full bg-primary flex items-center justify-center"
          >
            <img :src="AuxoIcon" class="w-4 h-4" />
          </div>
          <div class="flex flex-col">
            <h1>Auxo AI</h1>
            <p class="text-xs">
              <DescriptionPopover
                :hoverText="'Disclaimer'"
                :id="'disclaimer'"
                :link="'https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734'"
              >
                <h3 class="text-sm font-bold">Disclaimer</h3>
                <p>
                  This AI assistant is designed to provide guidance and
                  recommendations to assist with decision-making. While it
                  strives to provide accurate and helpful guidance, it is not
                  perfect and may make mistakes. Users are responsible for
                  ensuring that their actions comply with industry guidelines,
                  regulations, and best practices. If you find an issue, please
                  report it via in-product feedback or support.
                </p>
                <p>
                  <a
                    href="https://revology.atlassian.net/servicedesk/customer/portal/1/article/64192734"
                    target="_blank"
                    class="underline text-blue-500"
                    >Read More</a
                  >
                </p>
              </DescriptionPopover>
            </p>
          </div>
        </div>
        <div class="flex gap-5 items-center">
          <BaseButton
            @click="clearChatBot"
            :disabled="responseLoading"
            :class="responseLoading ? 'hidden' : ''"
            ><img :src="TrashIcon"
          /></BaseButton>
          <BaseButton @click="closeChatBot">
            <img :src="CloseIcon2" />
          </BaseButton>
        </div>
      </div>
      <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
      <p class="text-gray-300 mb-3">
        How can I help? Ask me a question about your claim.
      </p>
      <div class="grow"></div>
      <div
        id="scrollableDiv"
        class="flex flex-col items-center gap-3 overflow-y-auto overflow-x-hidden pt-3 will-change-auto"
      >
        <div
          v-show="messageList.length < 1"
          v-for="button in props.buttonList"
          :key="button"
          class="w-full flex justify-center"
        >
          <button
            class="bg-gray-200 hover:bg-blue-100 w-5/6 rounded-lg text-left p-2"
            @click="askQuestion(button)"
          >
            {{ button }}
          </button>
        </div>

        <div
          v-for="message in messageList"
          :key="message.id"
          class="flex flex-col gap-4 w-full"
        >
          <ChatBubble
            :text="message.text"
            :user="message.user"
            :timestamp="message.timestamp"
          />
        </div>

        <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 w-full" />
        <form
          class="flex gap-3 w-full items-center"
          @submit.prevent="askQuestion()"
        >
          <div class="flex w-full flex-col">
            <input
              v-model="prompt"
              :disabled="responseLoading"
              required="true"
              type="text"
              placeholder="Ask Auxo about your claim"
              :class="[
                'border p-2 w-full',
                errorMessage ? 'border-red-500' : '',
                responseLoading ? 'bg-gray-200' : '',
              ]"
            />
            <p v-if="errorMessage" class="text-red-500 text-rev-sm">
              {{ errorMessage }}
            </p>
          </div>

          <SolidButton
            type="submit"
            :disabled="!!errorMessage"
            class="bg-secondary border-secondary p-2"
          >
            Send
          </SolidButton>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes pulseColors {
  0% {
    box-shadow: 0 0 0 0px rgba(132, 225, 188, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.animate-pulse-colors {
  animation: pulseColors 2s infinite;
}
</style>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { useUsersStore } from '@/stores/useUsers';
import { useChatbotStore } from '@/stores/useChatbot';

import SolidButton from './buttons/SolidButton.vue';
import BaseButton from './buttons/BaseButton.vue';
import ChatBubble from './chat-ai/ChatBubble.vue';
import CloseIcon2 from '@/assets/close-icon-2.svg';
import TrashIcon from '@/assets/trashcan.svg';
import AuxoIcon from '@/assets/auxo-icon.svg';
import DescriptionPopover from '@/components/DescriptionPopover.vue';

const props = defineProps({
  buttonList: {
    type: Array,
    default: () => [],
  },
  page: {
    type: String,
    default: '',
  },
  rspGroup: {
    type: Object,
    default: () => ({ phase: '', status: '', reason: '', rsn: '' }),
  },
});

const usersStore = useUsersStore();
const chatbotStore = useChatbotStore();

let showBot = ref(false);
let errorMessage = ref(false);
let prompt = ref('');
let messageList = ref([]);
let responseLoading = ref(false);

watch(prompt, () => {
  checkCharacterCount();
});

const openChatBot = () => {
  showBot.value = true;
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};
const closeChatBot = () => {
  showBot.value = false;
};

const clearChatBot = () => {
  messageList.value = [];
  chatbotStore.sessionId = '';
};

const askQuestion = async question => {
  let formattedQuestion = '';
  if (question) {
    formattedQuestion = question;
  } else {
    checkCharacterCount();
    if (!prompt.value.trim() || prompt.value.includes('`')) {
      errorMessage.value = 'Please enter a valid prompt';
      return;
    }
    formattedQuestion = prompt.value;
    prompt.value = '';
  }
  messageList.value = [
    ...messageList.value,
    {
      text: {
        body: formattedQuestion,
      },
      timestamp: new Date(),
      user: usersStore.activeUser,
    },
  ];
  messageList.value = [
    ...messageList.value,
    {
      timestamp: new Date(),
    },
  ];
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
  try {
    responseLoading.value = true;
    const response = await chatbotStore.generateAndFormatResponse(
      formattedQuestion,
      props,
      usersStore.activeUser,
      messageList.value
    );

    responseLoading.value = false;
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: response,
    };
  } catch (error) {
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: { body: `There was an error retreiving your response: ${error}` },
    };
  }
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};

const checkCharacterCount = () => {
  const characterLimit = 1000;
  let errorMes =
    prompt.value.length <= characterLimit
      ? null
      : `Prompt must be less than ${characterLimit} characters`;
  errorMessage.value = errorMes;
};
</script>
