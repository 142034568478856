<template>
  <TitleBar />
  <div class="flex flex-row overflow-none h-full pt-[4.5em]">
    <SideBar />
    <!-- The height here comes from the 4.5em height of the navbar -->
    <div class="overflow-y-auto overflow-x-hidden h-full p-7 w-full">
      <NotFound v-if="props.notFound" />
      <div v-else class="h-full">
        <slot />
        <div
          class="flex flex-col float-right pt-5 mt-5"
          v-if="route.name !== 'leadId'"
        >
          <SupportPopper />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SideBar from '@/components/SideBar.vue';
import SupportPopper from '@/components/SupportPopper.vue';
import TitleBar from '@/components/TitleBar.vue';
import { useRoute } from 'vue-router';
import NotFound from '@/views/common/NotFound.vue';

const route = useRoute();

const props = defineProps({
  notFound: {
    default: false,
    type: Boolean,
  },
});
</script>
