<template>
  <div
    :id="`${header.key}-table-cell`"
    :class="[
      'flex',
      numericHeaders.includes(header.type) ? 'mr-[12px]' : '',
      ,
      header.key === 'user_photo' ? 'w-[1.5em]' : '',
    ]"
  >
    <span
      v-if="header?.type === 'date'"
      :class="header?.source !== 'lead' ? 'flex justify-center w-full' : ''"
    >
      <div>
        {{
          item[header.key]
            ? formatDate(
                item[header.key],
                header.format,
                header.truncateTimezone
              )
            : ''
        }}
      </div>
    </span>
    <span
      v-if="header?.key === 'last_known_login'"
      class="flex text-left w-full"
    >
      <div>
        {{
          item[header.key]
            ? formatDate(
                item[header.key],
                header.format,
                header.truncateTimezone
              )
            : ''
        }}
      </div>
    </span>
    <span v-else-if="header?.key === 'is_priority'">
      <template v-if="'deactivated' in item">
        <img
          v-if="item.deactivated == true"
          title="Deactivated"
          :src="YellowExclamationPoint"
          class="h-[32px] !w-[200px]"
        />
        <img
          v-else-if="item[header.key]"
          title="Priority"
          :src="PriorityLong"
        />
      </template>
      <template v-else>
        <img
          v-if="item[header.key] && header?.source === 'lead'"
          title="Priority"
          :src="PriorityLong"
        />
        <img v-else-if="item[header.key]" title="Priority" :src="Priority" />
      </template>
    </span>
    <span
      v-else-if="centeredHeaders.includes(header?.type)"
      class="flex justify-center w-full"
    >
      <img
        v-if="isEditingRuleRank"
        :src="MoveRowIcon"
        class="cursor-pointer w-3"
        :draggable="false"
      />
      <div v-else-if="editMode">
        <BaseInput
          v-model="input"
          wrapper-class="mt-none"
          :disabled="item.is_default"
          class="rounded text-center"
          :blur="e => gameplan_store.reorder(e, item, input, index)"
          :min-num="1"
          :step="'1'"
          @keyup.enter="e => gameplan_store.reorder(e, item, input, index)"
        />
      </div>
      <span v-else>{{ item[header.key] }}</span>
    </span>
    <span
      v-else-if="header?.type === 'count'"
      class="flex flex-row-reverse w-full"
    >
      {{ item[header.key] ? item[header.key] : 0 }}</span
    >
    <span
      v-else-if="header?.type === 'currency'"
      class="flex flex-row-reverse w-full"
    >
      {{
        item[header.key]
          ? formatCurrency().format(item[header.key] || 0)
          : '$0.00'
      }}</span
    >
    <span
      v-else-if="header?.key === 'dropdown'"
      class="flex justify-center w-full"
    >
      <font-awesome-icon :icon="selected ? faChevronDown : faChevronRight" />
    </span>
    <span
      v-else-if="header?.key === 'work_list_assignee'"
      class="flex justify-center w-full"
    >
      <UserGroup :users="sortUsers(item?.assignees || [], users_store)" />
    </span>
    <span v-else-if="header?.key === 'user_photo'">
      <UserPicture :user="getUserDetails(item)" disable-popper="true" />
    </span>
    <span v-else-if="header?.key === 'move'">
      <img
        v-show="!item.is_default"
        :src="MoveRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
      />
    </span>
    <span v-else-if="header?.key === 'copy'">
      <img
        v-show="!item.is_default"
        :src="CopyRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();

            queryBuilder.resetQuery();
            queryBuilder.resetWorklist();

            queryBuilder.worklist = {
              work_list_name: item.work_list_name,
              description: item.description,
              owner: item.owner,
              assignees: item.assignees,
              is_priority: item.is_priority,
              work_list_name: `${item.work_list_name} (Copy)`,
            };

            queryBuilder.defaultWorklist = false;

            queryBuilder.query = item.criteria_json;
            router.push(`/gameplan/new`);
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'edit'">
      <img
        :src="EditRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();

            queryBuilder.resetQuery();
            queryBuilder.resetWorklist();

            queryBuilder.worklist = {
              work_list_id: item.work_list_id,
              work_list_name: item.work_list_name,
              description: item.description,
              owner: item.owner,
              mod_by: item.mod_by,
              mod_timest: item.mod_timest,
              assignees: item.assignees ?? [],
              start_timest: item.start_timest,
              end_timest: item.end_timest,
              is_priority: item.is_priority,
            };

            queryBuilder.defaultWorklist = item?.is_default || false;

            queryBuilder.query = item.criteria_json;
            router.push(`/gameplan/edit`);
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'trash'">
      <img
        v-show="!item.is_default"
        :src="TrashRowIcon"
        class="cursor-pointer hover:brightness-50"
        :draggable="false"
        @click="
          e => {
            e.stopPropagation();
            gameplan_store.deleteModal = item;
            queryBuilder.defaultWorklist = false;
          }
        "
      />
    </span>
    <span v-else-if="header?.key === 'is_active'">
      <span
        v-if="item?.is_active"
        class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full"
        >Active</span
      >
      <span
        v-else
        class="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full"
        >Inactive</span
      >
    </span>
    <span v-else-if="header?.key === 'edit_user'" class="h-[24px]">
      <button type="button" @click="() => openEditModal(item)">
        <svg
          class="text-primary hover:text-secondary"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z"
          />
        </svg>
      </button>
    </span>
    <span v-else-if="header?.key === 'informal_title'">
      <span
        v-for="(role, roleIndex) in item.user_roles"
        :key="roleIndex"
        class="text-white text-sm font-medium inline-flex items-center px-2.5 py-0.5 rounded-lg me-2 my-1"
        :class="{
          'bg-secondary': role === 'REP',
          'bg-primary': role === 'ADM',
          'bg-yellow-one': role === 'MGR',
        }"
      >
        <img
          v-if="role == 'REP'"
          class="w-3.5 h-3 me-1.5"
          :src="ContributorIcon"
        />

        <img
          v-else-if="role == 'ADM'"
          class="w-2.5 h-2.5 me-1.5"
          :src="AdminIcon"
        />
        <img
          v-else-if="role == 'MGR'"
          class="w-3.5 h-3 me-1.5"
          :src="LeaderIcon"
        />
        {{ formatUserRoles([role]) }}
      </span>
    </span>
    <span
      v-else
      :class="[
        'truncate',
        header.key === 'email' ? 'max-w-[25em]' : 'max-w-[15em]',
      ]"
      >{{ item[header.key] }}</span
    >
  </div>
</template>

<script setup>
import { formatCurrency, formatDate } from '@/utils/helpers';
import MoveRowIcon from '@/assets/move-row-icon.svg';
import YellowExclamationPoint from '@/assets/yellow_exclamation_point.svg';
import Priority from '@/assets/priority.svg';
import PriorityLong from '@/assets/priority_long.svg';
import CopyRowIcon from '@/assets/copy.svg';
import EditRowIcon from '@/assets/edit.svg';
import TrashRowIcon from '@/assets/trashcan.svg';
import AdminIcon from '@/assets/admin.svg';
import ContributorIcon from '@/assets/contributor.svg';
import LeaderIcon from '@/assets/leader.svg';
import BaseInput from '../../components/forms/BaseInput.vue';
import { watch } from 'vue';
import router from '@/router';
import UserGroup from '@/components/UserGroup.vue';
import UserPicture from '@/components/UserPicture.vue';
import { sortUsers, formatUserRoles } from '@/utils/helpers';

import { numericHeaders, centeredHeaders } from '../../utils/constants';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { ref, inject } from 'vue';
import { useGamePlanStore } from '@/stores/useGamePlans';
import { useQueryBuilderStore } from '@/stores/useQueryBuilder';
import { useUsersStore } from '@/stores/useUsers';
import { useAdminStore } from '@/stores/useAdmin';
import { Modal } from 'flowbite';

const gameplan_store = useGamePlanStore();
const queryBuilder = useQueryBuilderStore();
const users_store = useUsersStore();
const adminStore = useAdminStore();

const input = ref(String(props.item[props.header.key]));

const userModalControls = inject('userModalControls');

watch(
  () => props.item,
  () => {
    input.value = String(props.item[props.header.key]);
  }
);

function getUserDetails(user) {
  let foundUser = adminStore.getUserById(user.auxo_user_id);
  if (foundUser.initials == '') {
    const initials = [
      user?.first_name?.[0].toUpperCase(),
      user?.last_name?.[0].toUpperCase(),
    ].join('');

    user.initials = initials;
    return user;
  }
  return foundUser;
}

function openEditModal(item) {
  adminStore.modalAction = 'edit';
  adminStore.selectedUser = {};
  adminStore.selectedUser = item;
  userModalControls.value.show();
}

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  header: {
    type: Object,
    default: () => {},
  },
  isEditingRuleRank: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  edit: {
    default: undefined,
    type: Boolean,
  },
  editMode: {
    default: false,
    type: Boolean,
  },
  index: {
    default: null,
    type: Number,
  },
});
</script>
