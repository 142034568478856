<template>
  <div @mouseover="updateStatsDetails(true)">
    <StatsGrid />
  </div>
</template>

<script setup>
import { useMetrics } from '@/stores/useMetrics';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import StatsGrid from './StatsGrid.vue';

const route = useRoute();
const metrics_store = useMetrics();

defineProps({
  updateStatsDetails: {
    type: Function,
    default: () => 0,
  },
});
</script>

<style lang="scss" scoped></style>
