<template>
  <div class="m-0.5 p-1" :class="props.containerClass">
    <label
      v-if="label"
      :for="id"
      class="p-0.5 text-gray-500"
      :class="labelClass"
      >{{ label }}</label
    >

    <select
      :id="id"
      :value="modelValue"
      name="select-input"
      class="bg-white p-1 w-full text-xs rounded border-solid border-2 border-dark-gray cursor-pointer"
      :class="props.class"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <!-- Show placeholder only when it is passed as prop -->
      <option v-if="props.placeholder" value="" disabled>
        {{ props.placeholder }}
      </option>

      <option
        v-if="!props.required && options.length != 0"
        :value="null"
      ></option>

      <!-- Show no options label always, when there is no options -->
      <option v-if="options.length == 0" value="" disabled>No Options</option>

      <option
        v-for="(option, index) in options"
        :key="index"
        class="block border-b-solid border-b-2 p-1 cursor-pointer hover:bg-gray-200"
        :value="option.key"
      >
        {{ option.value }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import isEqual from 'lodash/isEqual';

const emit = defineEmits(['update:modelValue']);

// Clear the select input value if the options change
// and the value is no longer valid

const id = `select-input-${Date.now()}`;

watch(
  () => props.options,
  (newOptions, oldOptions) => {
    // Options changed
    if (!isEqual(newOptions, oldOptions)) {
      // Is existing option still valid
      if (!newOptions.find(el => el.key == props.modelValue)) {
        emit('update:modelValue', null);
      }
    }
  }
);

const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  modelValue: {
    default: '',
    type: String,
  },
  options: {
    // object/array defaults should be returned from a factory function
    default() {
      return [];
    },
    type: Array,
  },
  placeholder: {
    default: '',
    type: String,
  },
  class: {
    default: '',
    type: String,
  },
  containerClass: {
    default: 'flex flex-col justify-end ',
    type: String,
  },
  labelClass: {
    default: 'font-bold',
    type: String,
  },
  required: {
    default: false,
    type: Boolean,
  },
});
</script>
